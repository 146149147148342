<script setup lang="ts">
const { $flavorManager, $geolocation } = useNuxtApp();
const browser = ref(getBrowser());

function getBrowser(): 'Chrome' | 'Firefox' | null {
    if (navigator.userAgent.includes('Chrome')) {
        return 'Chrome';
    }
    else if (navigator.userAgent.includes('Firefox')) {
        return 'Firefox';
    }
    else {
        return null;
    }
}
</script>

<template>
    <div>
        <AppDownload />
        <!-- leave this blank ('default' layout) to allow override in pages -->
        <NuxtLayout>
            <slot></slot>
        </NuxtLayout>

        <BModal
            :model-value="!$geolocation.initialized"
            hide-header
            class="location-modal"
            hide-footer
            no-stacking
            :aria-labelledby="null"
            @hide.prevent
        >
            <div v-if="$geolocation.denied" class="d-flex flex-column align-items-center">
                <div class="d-flex flex-column align-items-center">
                    <img
                        src="/location-denied.svg"
                        alt=""
                    >
                    <h2 class="h4 text-danger">GPS Access Denied</h2>
                    <p class="text-center">Your device blocked access to your location. Please check your settings to allow access to your location.</p>
                    <BAccordion class="w-100">
                        <BAccordionItem
                            title="Instructions for Android phone"
                            header-tag="h3"
                        >
                            <ol>
                                <li>Swipe down from the top of the screen.</li>
                                <li>
                                    If <em>Location <FontAwesomeIcon icon="fa-location-dot" /></em> is disabled,
                                    touch and hold <em>Location <FontAwesomeIcon icon="fa-location-dot" /></em> to enable location services.
                                </li>
                                <li v-if="browser === 'Firefox'">
                                    Make sure that location access is enabled in Firefox. In Firefox, tap <em>More <FontAwesomeIcon icon="fa-ellipsis-vertical" /></em> .
                                    Tap <em>Site permissions &gt; Location</em>, select <em>Location</em> and make sure it is enabled.
                                </li>
                                <li v-else>
                                    Make sure that location access is enabled in your browser. In your browser, tap <em>More <FontAwesomeIcon icon="fa-ellipsis-vertical" /></em> .
                                    Tap <em>Site settings &gt; Location </em> and turn <em>Location</em> on. To change the settings for this site, tap the site name listed below.
                                </li>
                            </ol>
                        </BAccordionItem>
                        <BAccordionItem
                            title="Instructions for iOS phone"
                            header-tag="h3"
                        >
                            <ol>
                                <li>Open the Settings app</li>
                                <li>Go to Privacy &amp; Security > Location Services</li>
                                <li>Make sure that Location Services is <em>on</em></li>
                                <li>In the Settings app, find and tap your browser (Safari, Chrome, ...)</li>
                                <li>Tap <em>Location</em>.</li>
                                <li>Make sure that Location access is enabled</li>
                            </ol>
                        </BAccordionItem>
                    </BAccordion>
                </div>
            </div>
            <div v-else class="d-flex flex-column align-items-center">
                <img
                    :src="`/flavors/${$flavorManager.flavor}/location-waiting.svg`"
                    alt=""
                >
                <div class="mb-2"><span class="loader"></span></div>
                <p class="text-center">Waiting on your location…</p>
            </div>
        </BModal>
        <WalkMe />
    </div>
</template>

<style lang="scss" scoped>
.location-modal {
    img {
        max-width: 180px;
        aspect-ratio: 1;
        margin-bottom: 1.5rem;
    }
}

li > em {
    font-weight: 700;
    font-style: normal;
}
</style>
