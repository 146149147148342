<script setup lang="ts">
const { $settings } = useNuxtApp();
const userInfo = currentUser();

userInfo.$subscribe((state) => {
    if (userInfo.pinInfo && state.storeId === 'currentUser') {
        const userId = userInfo.getUserId();
        if (userId && userInfo.pinInfo.client?.name) {
            window.user_id = `${userId} - ${userInfo.pinInfo.client.name}`;
        }
    }
});

if ($settings.walkMeUrl) {
    useHead({
        script: [
            {
                src: $settings.walkMeUrl,
                async: true,
            },
        ],
    });
}

window._walkmeConfig = { smartLoad: true };
</script>

<template>
    <div></div>
</template>
