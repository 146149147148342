<script setup lang="ts">
const { $flavorManager } = useNuxtApp();

const appUri = ref<string | null>(null);

onMounted(() => {
    if ($flavorManager.flavor !== 'protos') {
        return;
    }

    const hiddenDate = localStorage.getItem('appDownloadHidden');
    if (hiddenDate) {
        const hidden = dayjs(localStorage.getItem('appDownloadHidden'));
        if (dayjs().isBefore(hidden)) {
            return;
        }
    }

    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        appUri.value = 'https://play.google.com/store/apps/details?id=com.protossecurity.Psrm.App.Android';
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        appUri.value = 'https://apps.apple.com/us/app/protos-connect/id1610768348';
    }
});

function hide() {
    localStorage.setItem('appDownloadHidden', dayjs().add(7, 'days').toISOString());
    appUri.value = null;
}
</script>

<template>
    <div
        v-if="appUri"
        id="app-download"
        class="d-flex flex-row justify-space-between align-items-center py-2 px-3 gap-2 shadow text-white"
    >
        <button class="btn text-white px-0" @click.prevent="hide">
            <FontAwesomeIcon icon="fa-times" />
        </button>
        <img
            src="/images/protos-connect-icon.svg"
            alt=""
            class="protos-connect-icon"
        >
        <div class="flex-grow-1">
            <h6 class="my-0">Protos Connect</h6>
            <p class="my-0 lh-sm"><small>Gain access to expanded guarding features</small></p>
        </div>
        <a :href="appUri" class="btn py-1 rounded-pill text-primary bg-white">GET</a>
    </div>
</template>

<style lang="scss" scoped>
#app-download {
    justify-content: space-between;
    background: var(--bs-gray-400);
    position: relative;
    z-index: 1000;
}

.protos-connect-icon {
    height: 4rem;
    width: 4rem;
}
</style>
